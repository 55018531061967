import googlePayment from 'braintree-web/google-payment'
import { createClient } from '@/util/braintree.js'

import Tracker from '@/util/tracker.js'

let scriptAdded = false

async function addScript() {
  if (scriptAdded) {
    return
  }

  return await new Promise((resolve) => {
    const element = document.createElement('script')
    element.addEventListener('load', resolve)
    element.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js')
    document.head.appendChild(element)
    scriptAdded = true
  })
}

/**
 * @typedef {{
 *   authorization: string
 *   transactionId: string
 *   totalPrice: string
 *   currency: string
 *   radius: number
 * }} CreateOptions
 *
 *
 * @typedef {{
 *   instance: import('braintree-web').GooglePayment
 *   updateTotalPrice(price: string): void
 * }} CreateResult
 */

/**
 * @param {HTMLElement} container
 * @param {CreateOptions} options
 * @returns {Promise<CreateResult?>}
 */
export async function create(container, options) {
  await addScript()
  if (window.google == null) {
    console.log('Google Pay is not available')
    return null
  }

  const {
    authorization,
    transactionId,
    totalPrice,
    currency: currencyCode,
    radius = 4,
  } = options

  const transactionInfo = {
    totalPrice,
    currencyCode,
    totalPriceStatus: 'ESTIMATED',
  }

  if (transactionId) {
    transactionInfo.transactionId = transactionId
  }

  const instance = await googlePayment.create({
    client: await createClient({ authorization }),
    googlePayVersion: 2,
    googleMerchantId: RAILS_ENV === 'production' ? 'BCR2DN4TYTZPZU3A' : null,
  })

  let paymentDataRequest = instance.createPaymentDataRequest({
    transactionInfo,
    emailRequired: true,
    shippingAddressRequired: true,
  })

  const paymentsClient = new window.google.payments.api.PaymentsClient({
    environment: RAILS_ENV === 'production' ? 'PRODUCTION' : 'TEST',
  })

  const readyToPay = await paymentsClient.isReadyToPay({
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods,
    existingPaymentMethodRequired: true,
  })
  if (!readyToPay.result) {
    console.log('Google Pay is not ready')
    return null
  }

  const button = paymentsClient.createButton({
    buttonSizeMode: 'fill',
    buttonType: 'plain',
    buttonRadius: radius,
    async onClick(_ev) {
      container.dispatchEvent(
        new CustomEvent('googlePayClick', { bubbles: true }),
      )

      const paymentData =
        await paymentsClient.loadPaymentData(paymentDataRequest)
      const result = await instance.parseResponse(paymentData)
      container.dispatchEvent(
        new CustomEvent('googlePayDataLoaded', {
          bubbles: true,
          detail: {
            googlePay: instance,
            paymentData: {
              ...result,
              details: {
                ...result.details,
                rawPaymentData: paymentData,
              },
            },
          },
        }),
      )
    },
  })

  container.appendChild(button)

  return {
    instance,
    updateTotalPrice(price) {
      transactionInfo.totalPrice = price
      paymentDataRequest = instance.createPaymentDataRequest({
        transactionInfo,
        emailRequired: true,
        shippingAddressRequired: true,
      })
    },
  }
}

class GooglePayButton extends HTMLElement {
  get paymentMethodName() {
    return 'Google Pay'
  }

  static observedAttributes = ['totalprice']

  /**
   * @param {string} name
   * @param {string} _oldValue
   * @param {string} value
   */
  attributeChangedCallback(name, _oldValue, value) {
    switch (name) {
      case 'totalprice':
        this.googlePayment?.updateTotalPrice(value)
        break

      default:
        console.warn(`unexpected observed attribute changed: ${name}`)
        break
    }
  }

  async connectedCallback() {
    if (this.once) {
      return
    }
    this.once = true

    this.abort = new AbortController()
    const { signal } = this.abort

    this.googlePayment = await create(this, {
      authorization: this.getAttribute('authorization'),
      transactionId: this.getAttribute('transactionid'),
      totalPrice: this.getAttribute('totalprice'),
      currency: this.getAttribute('currency'),
      radius: this.hasAttribute('radius')
        ? parseFloat(this.getAttribute('radius'))
        : undefined,
    })
    if (this.googlePayment == null) {
      this.classList.add('hidden')
    }

    const location = this.getAttribute('page')

    await Tracker.track('express button loaded', {
      type: 'google pay',
      location,
    })
    ;[
      ['googlePayClick', 'clicked'],
      ['googlePayDataLoaded', 'approved'],
    ].forEach(([event, name]) => {
      this.addEventListener(
        event,
        async () => {
          await Tracker.track(`express button ${name}`, {
            type: 'google pay',
            location,
          })
        },
        { signal },
      )
    })
  }

  disconnectedCallback() {
    this.abort.abort()
  }
}

customElements.define('googlepay-button', GooglePayButton)
